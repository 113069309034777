body {
  margin: 0;
  padding: 0;
  height: 100vh;
  width: 100%;
}
.projectimg img {
  height: 300px !important;
  width: 100% !important;
  object-fit: cover !important;
}
.content-wrapper {
  position: relative;
  text-align: center;
  padding: 60px 20px;
}

.background-overlay {
  background: #333333;
  background-size: cover;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.content-box {
  position: relative;
  background-color: rgba(255, 255, 255, 0.9);
  padding: 30px;
  max-width: 80%;
  margin: 0 auto;
  border-radius: 8px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.headline {
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 20px;
}

.sectors {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 30px;
}

.sectors span {
  margin: 0 5px;
}

@media screen and (max-width: 600px) {
  .content-box {
    max-width: 100%;
  }
}


